import { CatalogProductModel } from 'models/catalog/products'
import { Product } from 'types/catalog'

import { hasSponsoredProductAtLocalStorage } from '@k_frontend/core'
import { FormatProductProps } from './formatProductToDataLayer.types'

function getPrices(product, isUserPrime) {
  if (product.isPrime && isUserPrime) {
    return product.discountPrimePrice?.toFixed(2)
  }

  return (
    product.priceWithDiscount?.toFixed(2) ||
    product.price?.toFixed(2) ||
    product.discountPrice?.toFixed(2)
  )
}

export function formatToLowerCaseHyphenated(inputString: string) {
  const formatedString = inputString.toLocaleLowerCase().replace(/\s/g, '-')
  return formatedString
}

export function formatProduct({
  products,
  title,
  smarthint = false,
  isUserPrime = false
}: FormatProductProps) {
  return products.map((product, index) => ({
    name: product.name,
    id: String(product.code),
    price: getPrices(product, isUserPrime),
    brand:
      product?.manufacturer?.name || product?.manufacturer || product?.brand,
    position: index + 1,
    ...(title ? { list: title } : null),
    ...(smarthint ? { category_event: 'SmartHint' } : null),
    dimension20: product?.sellerId || 0,
    dimension21: product?.sellerName || 'KaBuM!',
    dimension01: hasSponsoredProductAtLocalStorage(product.code),
    category: product.category
  }))
}

export function formatProductsImpressions(
  products: CatalogProductModel[],
  title?: string,
  smarthint?: boolean
) {
  return formatProduct({
    products,
    title: formatToLowerCaseHyphenated(title),
    smarthint
  })
}

export function formatProductsCatalog(products: Product[], title?: string) {
  return formatProduct({ products, title })
}
